<template>
  <div id="container">
    <span class="loading-box" v-if="outsideControl">
      loading:{{ percent }}%
      <!-- {{ item.image }} -->
    </span>
  </div>
</template>
<script>
import * as THREE from "three";
// import { OBJLoader, MTLLoader } from "three-obj-mtl-loader";
// import {DDSLoader} from "three/examples/jsm/loaders/DDSLoader";
// import { CSS2DRenderer, CSS2DObject } from "three-css2drender";
// import { AmbientLight, LightShadow } from "three";
// const OrbitControls = require("three-orbit-controls")(THREE);

// import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
// import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import { TDSLoader } from "three/examples/jsm/loaders/TDSLoader.js";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
// import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// import { GUI } from "three/examples/jsm/libs/dat.gui.module.js";
// import { HDRCubeTextureLoader } from "three/examples/jsm/loaders/HDRCubeTextureLoader.js";
// import { HDRCubeTextureLoader } from "three/examples/jsm/loaders/HDRCubeTextureLoader.js";

// import {STLLoader} from 'three/examples/jsm/loaders/STLLoader';
export default {
  name: "vue-three",
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      outsideControl: true,
      percent: 0,
      // gui: null,

      state: { variant: "midnight" },
    };
  },
  methods: {
    // 初始化场景
    init() {
      let container = document.getElementById("container");
      /* 
        场景
      */
      this.camera = new THREE.PerspectiveCamera(
        45,
        document.documentElement.clientWidth /
          document.documentElement.clientHeight,
        10,
        4000
      );
      // this.camera.position.set(2.5, 1.5, 3.0);

      this.camera.position.set(0, 90, 200);

      this.scene = new THREE.Scene();

      // this.scene.background = new THREE.Color(0xffffff);
      this.scene.background = new THREE.Color(0xa0a0a0);

      /////////

      this.scene.fog = new THREE.Fog(0xa0a0a0, 200, 1000);

      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
      hemiLight.position.set(0, 0, 0);
      // this.scene.add(hemiLight);

      //  let ambientLight = new THREE.AmbientLight("#ffffff");
      // this.scene.add(ambientLight);

      const dirLight = new THREE.DirectionalLight(0xffffff);
      dirLight.position.set(0, 500, 500);
      dirLight.castShadow = true;
      // dirLight.shadow.camera.top = 180;
      // dirLight.shadow.camera.bottom = -100;
      // dirLight.shadow.camera.left = -120;
      // dirLight.shadow.camera.right = 120;
      this.scene.add(dirLight);

      // scene.add( new THREE.CameraHelper( dirLight.shadow.camera ) );

      // ground
      const mesh = new THREE.Mesh(
        new THREE.PlaneGeometry(2000, 2000),
        new THREE.MeshPhongMaterial({ color: 0x999999, depthWrite: false })
      );
      mesh.rotation.x = -Math.PI / 2;
      mesh.receiveShadow = true;
      this.scene.add(mesh);

      const grid = new THREE.GridHelper(2000, 20, 0x000000, 0x000000);
      grid.material.opacity = 0.2;
      grid.material.transparent = true;
      this.scene.add(grid);
      grid.receiveShadow = true;

      console.log("hello");

      const normal0 = new THREE.TextureLoader().load(
        "models/gltf/zenexus/jinshu2.jpg"
      );

      const normal1 = new THREE.TextureLoader().load(
        "models/gltf/zenexus/image1.jpg"
      );

      const normal2 = new THREE.TextureLoader().load(
        "models/gltf/zenexus/image2.jpg"
      );

      const loader = new TDSLoader();
      // loader.setResourcePath( 'models/3ds/portalgun/textures/' );
      loader.load(
        "models/gltf/zenexus/23DS.3DS",
        (object) => {
          // console.log("hello1");

          object.traverse((child) => {
            // console.log("hello2");

            if (child.isMesh) {
              // child.material.specular.setScalar( 0.1 );

              // console.log(child.name);

              if (child.name == "baisejinsh") {
                console.log("baisejinsh");
                child.material.normalMap = normal1;
                this.render();
              } else if (child.name == "chelun") {
                console.log("chelun");

                child.material.normalMap = normal2;
                this.render();
              } else {
                child.material.normalMap = normal0;
              }

              //    child.castShadow = true;
              //    child.receiveShadow = true;
            }
          });

          object.rotateX(-Math.PI / 2);

          this.scene.add(object);
          this.render();
        },
        (xhr) => {

          this.percent =  parseInt( (xhr.loaded / xhr.total) * 100,10);

          if(this.percent>99)
          {
            this.outsideControl=false;
          }
          // console.log(
          //   "加载完成的百分比" + (xhr.loaded / xhr.total) * 100 + "%"
          // );
        }
      );

      /*

      const loader = new STLLoader();
      loader.load("models/gltf/zenexus/STL.STL", (geometry) => {
        const material = new THREE.MeshPhongMaterial({
          color: 0xff5533,
          specular: 0x111111,
          shininess: 200,
        });
        const mesh = new THREE.Mesh(geometry, material);

        mesh.position.set(0, -0.25, 0.6);
        mesh.rotation.set(0, -Math.PI / 2, 0);
        mesh.scale.set(0.5, 0.5, 0.5);

        mesh.castShadow = true;
        mesh.receiveShadow = true;

        this.scene.add(mesh);
      });
      */

      /*
      const loader = new FBXLoader();
			loader.load( 'models/gltf/zenexus/1.fbx',  ( object )=> {

				//	mixer = new THREE.AnimationMixer( object );

			//		const action = mixer.clipAction( object.animations[ 0 ] );
				    //action.play();

				

					object.traverse( function ( child ) {

						if ( child.isMesh ) {

							child.castShadow = true;
							child.receiveShadow = true;

						}

					} );
	

					this.scene.add( object );

				} );

        */

      /*

      const loader = new GLTFLoader().setPath(

        "models/gltf/zenexus/"
      );
      // loader.load("MaterialsVariantsShoe.gltf",  (gltf)=> {

      loader.load("5.gltf", (gltf) => {
        // gltf.scene.scale.set(10.0, 10.0, 10.0);
        gltf.scene.scale.set(0.8, 0.8, 0.8);

        this.scene.add(gltf.scene);

        var grid = new THREE.GridHelper(2000, 20, 0x000000, 0x000000);
        grid.material.opacity = 0.2;
        grid.material.transparent = true;
        this.scene.add(grid);
      console.log("hello1");

       this.render();
      });

      */

      /*
      var grid = new THREE.GridHelper(2000, 20, 0x000000, 0x000000);
      grid.material.opacity = 0.2;
      grid.material.transparent = true;
      this.scene.add(grid);
      */

      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      // this.renderer.setSize(window.innerWidth, window.innerHeight);

      this.renderer.setSize(
        document.documentElement.clientWidth,
        document.documentElement.clientHeight
      );

      this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 1;
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      container.appendChild(this.renderer.domElement);

      const controls = new OrbitControls(this.camera, this.renderer.domElement);
      controls.addEventListener("change", this.render); // use if there is no animation loop
      controls.minDistance = 50;
      controls.maxDistance = 1000;
      // controls.target.set(0, 0.5, -0.2);

      controls.minPolarAngle = Math.PI / 6;
      controls.maxPolarAngle = Math.PI / 1.5;
      controls.target.set(0, 60, 0);
      controls.update();
      window.addEventListener("resize", this.onWindowResize);

      //   this.light();
      this.render();
    },

    render() {
      this.renderer.render(this.scene, this.camera);
    },

    onWindowResize() {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
    // 灯光效果

    light() {
      // let light = new THREE.DirectionalLight(0xdfebff, 0.45);
      let light = new THREE.DirectionalLight(0xffffff, 0.45); //从正上方（不是位置）照射过来的平行光，0.45的强度
      //从正上方（不是位置）照射过来的平行光，0.45的强度
      light.position.set(50, 500, 500);
      // light.position.multiplyScalar(0.3);

      // this.scene.add(light);
      //添加环境光
      // let ambientLight = new THREE.AmbientLight("#ffffff");
      // this.scene.add(ambientLight);
    },
    // 动画效果

    /*

   
    animate() {
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
    */
  },
  mounted() {
    this.init();

    // this.animate();
  },
};
</script>

<style>
body {
  /* background-color: red; */
  margin: 0 !important;
}
* {
  margin: 0;
  padding: 0;
}
#container {
  /* width: 800px;
  margin: 0 auto;
  height: 500px;
  overflow: hidden; */
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;

  background-color: white;
}
.loading-box {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255);
}
</style>
